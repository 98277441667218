import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Stack, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { GenericCard, MolecularFormula, OverflowText } from '@components/common';
import { nmrDrmService, NmrDrmDetail, IrDrmDetail, irDrmService } from '@services';
import { DataTestId, mainTitleSpacer, RxUtils } from '@utils';
import { substanceService } from '@services/core/substance';
import { useService } from '@hooks/useService';
import { SubstanceRequests } from '../SubstanceDatabase';
import { NmrDrmOrder, nmrDrmOrderService, NmrDrmOrderStatus } from '@services/nmr-drm-pipeline';
import { DigitalReferenceCard } from './DigitalReferenceCard';
import { DigitalReferenceOrderCard } from './DigitalReferenceOrderCard';
import { ExtendedComponent } from 'src/types';
import { EmptySubstanceDetailSection } from './EmptySubstanceDetailSection';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '@store/slices/common.slice';

const DrmRow: ExtendedComponent<{ label: string; result?: string | number; width?: string }> = ({ label, result, children, width }) => {
	return (
		<Stack direction="row" width="100%" marginBottom={1.5}>
			<Stack width="222px">
				<Typography variant="body1" color="grey.900" sx={{ lineHeight: '160%' }}>
					{label}
				</Typography>
			</Stack>

			<Stack width={width}>
				{children}
				{!children ? (
					<Typography variant="body1" color="grey.800" sx={{ lineHeight: '160%' }}>
						{result ?? '-'}
					</Typography>
				) : null}
			</Stack>
		</Stack>
	);
};

export const SubstanceDetail: FC = () => {
	const { id } = useParams();
	if (!id) return null;

	const { t } = useTranslation('admin');
	const dispatch = useDispatch();

	const media = useMediaQuery((theme: Theme) => theme.breakpoints.down(1350));
	const [references, setReferences] = useState<(NmrDrmDetail | IrDrmDetail)[]>([]);
	const [drmOrders, setDrmOrders] = useState<NmrDrmOrder[]>([]);

	const { data: substanceResponse } = useService(() => substanceService.get(+id));
	const substance = substanceResponse;

	const molecularFormulaRef = React.createRef<HTMLDivElement>();
	const fetchDrmOrders = (substanceId: number | string) => {
		if (substanceId) {
			const urlSearchParams: URLSearchParams = new URLSearchParams({
				substanceId: `${substanceId}`,
				pageIndex: '1',
			});
			RxUtils.promisify(nmrDrmOrderService.getAllWithSearchParams(urlSearchParams), (response) => setDrmOrders(response.data));
		}
	};

	useEffect(() => {
		fetchDrmOrders(id);
		dispatch(setBreadcrumb({ substanceName: substance?.name || 'Substance' }));
		const setDetail = (detail: IrDrmDetail | NmrDrmDetail) => {
			setReferences((prev) => [...prev, detail]);
		};

		if (substance?.id) {
			const urlSearchParams = new URLSearchParams();
			urlSearchParams.append('SubstanceId', `${substance.id}`);
			RxUtils.promisify(nmrDrmService.searchAllWithUrlParams(urlSearchParams), (res) => {
				res.data.forEach(setDetail);
			});
			RxUtils.promisify(irDrmService.getBySearchParams(urlSearchParams), (res) => {
				res.data.forEach(setDetail);
			});
		}
	}, [substanceResponse]);

	return (
		<>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				{substance?.name && (
					<OverflowText
						enableTooltip={true}
						containerProps={{ width: 'auto !important', maxWidth: 'calc(100% - 10rem)', marginRight: 2 }}
						variant="h1"
						color="primary.main"
					>
						{substance.name}
					</OverflowText>
				)}
			</Stack>

			<GenericCard sx={{ marginTop: mainTitleSpacer, pl: 3 }}>
				<Stack data-testid={DataTestId.getStaticTestId('substance-detail-wrapper')} direction="row">
					<MolecularFormula
						width={376}
						height={media ? 150 : 280}
						sx={{ maxWidth: '376px', width: '100%', height: '282px' }}
						smilesFormula={substance?.smilesCode}
						ref={molecularFormulaRef}
					/>

					<Stack direction="column" marginLeft={7} width="100%">
						<DrmRow label={t('substance-detail.cas-number')} result={substance?.casNumber} />
						<DrmRow label={t('substance-detail.molecular-formula')} result={substance?.linearMolecularFormula} />
						<DrmRow label={t('substance-detail.molecular-weight')} result={substance?.molecularWeight + ' g/mol'} />
						<DrmRow label={t('substance-detail.smiles')} result={substance?.smilesCode}>
							<Tooltip
								placement="top"
								PopperProps={{
									sx: {
										maxHeight: '50%',
										overflow: 'auto',
										zIndex: 1000000,
										' .MuiTooltip-tooltip': { marginBottom: '0 !important' },
									},
								}}
								title={<>{substance?.smilesCode}</>}
							>
								<OverflowText enableTooltip={true} variant="pg-m" color="grey.800" sx={{ lineHeight: '160%' }}>
									{substance?.smilesCode?.toUpperCase() ?? '-'}
								</OverflowText>
							</Tooltip>
						</DrmRow>
						<DrmRow label={t('substance-detail.synonyms')} width="30%">
							<Tooltip
								placement="top"
								PopperProps={{
									sx: {
										maxHeight: '50%',
										overflow: 'auto',
										zIndex: 1000000,
										' .MuiTooltip-tooltip': { marginBottom: '0 !important' },
									},
								}}
								title={<>{substance?.synonyms}</>}
							>
								<>
									<OverflowText enableTooltip={true} variant="pg-m" color="grey.800" sx={{ lineHeight: '160%' }}>
										{substance?.synonyms ?? ''}
									</OverflowText>
								</>
							</Tooltip>
						</DrmRow>
						{substance && substance.id ? (
							<DrmRow label={t('substance-detail.requests')}>
								<SubstanceRequests substanceId={substance.id} casNumber={substance.casNumber} />
							</DrmRow>
						) : (
							<DrmRow label={t('substance-detail.requests')} result="-" />
						)}
					</Stack>
				</Stack>
			</GenericCard>

			<Typography variant="h2" color="primary.main" marginTop={5.25} marginBottom={1.5}>
				{t('substance-detail.digital-references')}
			</Typography>
			{references.length === 0 ? (
				<EmptySubstanceDetailSection
					title={t('substance-detail.no-digital-reference-title')}
					explanation={t('substance-detail.no-digital-reference-explanation')}
				/>
			) : (
				<>
					<Stack flexWrap="wrap" flexDirection="row" rowGap={3} divider={<Divider sx={{ marginX: 1 }} />}>
						{substance &&
							references
								.sort((a, b) => (a.isActive && !b.isActive ? 1 : 0))
								.map((dRM: NmrDrmDetail | IrDrmDetail) => (
									<DigitalReferenceCard key={dRM.id} dRM={dRM} substance={substance} />
								))}
					</Stack>
				</>
			)}

			<Typography variant="h2" color="primary.main" marginTop={5.25} marginBottom={1.5}>
				{t('substance-detail.drm-orders')}
			</Typography>
			{drmOrders.length === 0 ? (
				<EmptySubstanceDetailSection
					title={t('substance-detail.no-drm-order-title')}
					explanation={t('substance-detail.no-drm-order-explanation')}
				/>
			) : (
				<>
					<Stack flexWrap="wrap" flexDirection="row" rowGap={3} divider={<Divider sx={{ marginX: 1 }} />}>
						{drmOrders
							.filter((drm) => drm.status !== NmrDrmOrderStatus.RELEASED)
							.map((dRMOrder) => (
								<DigitalReferenceOrderCard
									key={dRMOrder.id}
									dRMOrder={dRMOrder}
									data-testid={DataTestId.getStaticTestId('digital-reference-order-card-id')}
								/>
							))}
					</Stack>
				</>
			)}
		</>
	);
};
